var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "avatar-upload" }, [
    _c(
      "div",
      {
        staticClass: "avatar-upload-image",
        class: { hasbg: !_vm.imageUrl },
        on: { click: _vm.uploadAvatar }
      },
      [
        _c("input", {
          ref: "avatarupload",
          staticClass: "avatar-upload-image-upload",
          attrs: {
            type: "file",
            accept: "image/png, image/jpeg, image/gif, image/webp"
          },
          on: { change: _vm.onAvatarUpload }
        }),
        !_vm.imageUrl
          ? _c("img", {
              staticClass: "avatar-upload-image-icon",
              attrs: { src: require("@/assets/icons/globe.svg"), alt: "" }
            })
          : _c("img", {
              staticClass: "avatar-upload-image-logo",
              attrs: { src: _vm.imageUrl, alt: "" }
            }),
        _vm.imageUrl
          ? _c("img", {
              staticClass: "avatar-upload-image-remove",
              attrs: { src: require("@/assets/icons/close.svg"), alt: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.removeAvatar.apply(null, arguments)
                }
              }
            })
          : _vm._e()
      ]
    ),
    _c("div", { staticClass: "avatar-upload-image-action" }, [
      _c(
        "div",
        { staticClass: "avatar-upload-image-action-btns" },
        [
          _c("Button", {
            attrs: { text: "Upload image", type: "white" },
            on: { click: _vm.uploadAvatar }
          }),
          _vm.canGenerate
            ? _c(
                "GenerateAvatar",
                _vm._g(
                  { attrs: { content: _vm.generateContent } },
                  _vm.$listeners
                )
              )
            : _vm._e()
        ],
        1
      ),
      _c("p", { staticClass: "avatar-upload-image-action-info" }, [
        _vm._v(
          " " +
            _vm._s(_vm.width) +
            " x " +
            _vm._s(_vm.height || _vm.width) +
            " JPG, GIF, PNG or WEBP. Max size of " +
            _vm._s(_vm.maxSize) +
            "MB "
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
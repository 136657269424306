import { getClient, getResponseData } from '../../../store/src/axios'

const client = getClient('/workspaces')

export async function generateLogo({ workspace_id, content, amount }) {
  const res = await client.post(`/${workspace_id}/generate/logo/`, {
    content,
    amount
  })
  return getResponseData(res)
}

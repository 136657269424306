<template>
  <div class="generate-avatar">
    <b-tooltip
      label="Add content first"
      position="is-top"
      type="is-dark"
      :active="!content"
    >
      <Button
        text="Generate image"
        type="white"
        :disabled="!content"
        :loading="loading"
        @click="generateAvatar"
      />
    </b-tooltip>
    <div v-if="options.length" class="generate-avatar-options">
      <p class="generate-avatar-options-header">
        Generated images
        <Button size="xs" icon="close" type="grey" @click="clear" />
      </p>
      <div class="generate-avatar-options-list">
        <img
          v-for="option in options"
          :key="option.url"
          :src="option.url"
          alt=""
          class="generate-avatar-options-list-item"
          @click="() => selectAvatar(option)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { generateLogo } from '@/services/generateService'
import Button from './Button.vue'

export default {
  name: 'GenerateAvatar',
  components: { Button },
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    loading: false,
    options: []
  }),
  methods: {
    async generateAvatar() {
      try {
        this.loading = true
        this.$emit('generateLoading', true)
        this.generateLoading = true
        const { urls } = await generateLogo({
          workspace_id: this.$route.params.workspace_id,
          content: this.content,
          amount: 4
        })
        const images = await Promise.all(
          urls.map((url) =>
            fetch(url)
              .then((i) => i.blob())
              .then(
                (blobFile) =>
                  new File(
                    [blobFile],
                    `avatar.${blobFile.type.split('/').slice(-1)[0]}`,
                    { type: blobFile.type }
                  )
              )
          )
        )
        this.options = images.map((image, index) => ({
          url: urls[index],
          image
        }))
      } catch (e) {
        this.$console.debug('Error generating avatar', e)
        this.$toast.danger(
          'Error generating avatar',
          'Please try again later or contact us if the problem persists.'
        )
      } finally {
        this.loading = false
      }
    },
    selectAvatar(avatar) {
      this.$emit('input', avatar.url, avatar.image)
      this.clear()
    },
    clear() {
      this.options = []
    }
  }
}
</script>

<style lang="scss" scoped>
.generate-avatar {
  position: relative;

  &-options {
    position: absolute;
    top: calc(100% + 0.5rem);
    right: 0;
    width: min(25rem, 90vw);
    background: white;
    border-radius: 4px;
    border: 1px solid rgba(#000, 0.08);
    z-index: 11;

    &-header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1rem;
      font-weight: 600;
      font-size: 1.15rem;
    }

    &-list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.5rem;
      padding: 1rem;

      &-item {
        width: 100%;
        aspect-ratio: 1 / 1;
        border-radius: 4px;
        border: 1px solid rgba(#000, 0.08);
        cursor: pointer;

        &:hover {
          border: 1px solid rgba(#000, 0.16);
        }
      }
    }
  }
}
</style>

<template>
  <div class="avatar-upload">
    <div
      class="avatar-upload-image"
      :class="{ hasbg: !imageUrl }"
      @click="uploadAvatar"
    >
      <input
        ref="avatarupload"
        type="file"
        class="avatar-upload-image-upload"
        accept="image/png, image/jpeg, image/gif, image/webp"
        @change="onAvatarUpload"
      />
      <img
        v-if="!imageUrl"
        src="@/assets/icons/globe.svg"
        alt=""
        class="avatar-upload-image-icon"
      />
      <img v-else :src="imageUrl" alt="" class="avatar-upload-image-logo" />
      <img
        v-if="imageUrl"
        src="@/assets/icons/close.svg"
        alt=""
        class="avatar-upload-image-remove"
        @click.stop="removeAvatar"
      />
    </div>
    <div class="avatar-upload-image-action">
      <div class="avatar-upload-image-action-btns">
        <Button text="Upload image" type="white" @click="uploadAvatar" />
        <GenerateAvatar
          v-if="canGenerate"
          :content="generateContent"
          v-on="$listeners"
        />
      </div>
      <p class="avatar-upload-image-action-info">
        {{ width }} x {{ height || width }} JPG, GIF, PNG or WEBP. Max size of
        {{ maxSize }}MB
      </p>
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import FileToImageConvertMixin from '@c/mixins/FileToImageConvertMixin'
import GenerateAvatar from './GenerateAvatar.vue'

export default {
  name: 'AvatarUpload',
  components: {
    Button,
    GenerateAvatar
  },
  mixins: [FileToImageConvertMixin],
  props: {
    image: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 512
    },
    height: {
      type: Number,
      default: 0
    },
    maxSize: {
      type: Number,
      default: 5
    },
    canGenerate: {
      type: Boolean,
      default: false
    },
    generateContent: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    img: undefined,
    imageUrl: ''
  }),
  watch: {
    image(val) {
      this.imageUrl = val
    }
  },
  mounted() {
    this.imageUrl = this.image
  },
  methods: {
    uploadAvatar() {
      this.$refs.avatarupload.click()
    },
    onAvatarUpload(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files?.length) return
      if (!files[0]?.size) {
        this.$toast.danger(
          'Empty image file',
          'Please upload an image with content, or contact us if you think this is a mistake.'
        )
        return
      }
      if (files[0].size > this.maxSize * 1024 * 1024) {
        this.$toast.danger(
          'Image too large',
          `Please upload an image smaller than ${this.maxSize}MB`
        )
        return
      }
      this.img = files[0]
      this.imageToURL(this.img, (url) => {
        this.imageUrl = url || ''
        this.$emit('input', this.imageUrl, this.img)
      })
    },
    removeAvatar() {
      this.imageUrl = ''
      this.img = null
      this.$refs.avatarupload.value = ''
      this.$emit('input', this.imageUrl, this.img)
    }
  }
}
</script>

<style scoped lang="scss">
.avatar-upload {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  align-items: center;

  &-image {
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 12px;
    border: 2px dotted transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s;
    position: relative;

    &:hover {
      border: 2px dotted #dddfe2;
    }

    &.hasbg {
      background: #9bbbfd;

      &:hover {
        background: darken(#9bbbfd, 5%);
      }
    }

    &-icon {
      height: 4rem;
      filter: brightness(0) invert(1);
      opacity: 0.5;
    }

    &-logo {
      height: 5rem;
      width: 5rem;
      border-radius: 12px;
      object-fit: cover;
    }

    &-action {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;

      &-info {
        font-size: 0.8rem;
        color: #60666b;
      }

      &-btns {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 1rem;
      }
    }

    &-upload {
      opacity: 0;
      position: absolute;
      pointer-events: none;
    }

    &-remove {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      height: 1.5rem;
      width: 1.5rem;
      padding: 0.25rem;
      border-radius: 999rem;
      background: rgba(white, 0.5);
      cursor: pointer;

      &:hover {
        background: rgba(white, 0.75);
      }
    }
  }
}
</style>
